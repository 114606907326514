import React, { FC, useRef, useState } from 'react';
import { LogoSVG } from './LogoSVG';
import html2canvas from 'html2canvas';
import { Input } from './components/Input';
import { Button } from './components/Button';

export const App: FC<{}> = (props) =>
{
  const cardRef = useRef<HTMLDivElement>();
  const [ imageUrl, setImageUrl ] = useState<string>('');

  const [ name, setName ] = useState<string>('Maurício B Padrão');
  const [ passport, setPassport ] = useState<string>('4500');
  const [ pictureUrl, setPictureUrl ] = useState<string>('https://i.imgur.com/s1NTDdv.gif');
  const [ role, setRole ] = useState<string>('Advogado');
  const [ signRole, setSignRole ] = useState<string>('Juíza de Direito');
  const [ sign, setSign ] = useState<string>('Mia Villabele');

  const exportCard = () =>
  {
    if (!cardRef.current) return;

    html2canvas(cardRef.current, { useCORS: true }).then(function (canvas) {
      var myImage = canvas.toDataURL("image/png");
      setImageUrl(myImage);
  });
  };

  return (
    <div className="flex flex-col gap-5 p-5">
      <div>
        <div className="grid grid-cols-3 gap-3 mb-4">
          <div>
            <div className="text-sm font-medium">Nome Completo</div>
            <Input type="text" value={ name } onChange={ setName } />
          </div>
          <div>
            <div className="text-sm font-medium">Passaporte</div>
            <Input type="text"  value={ passport } onChange={ setPassport } />
          </div>
          <div>
            <div className="text-sm font-medium">URL da Imagem</div>
            <Input type="text"  value={ pictureUrl } onChange={ setPictureUrl } />
          </div>
          <div>
            <div className="text-sm font-medium">Cargo</div>
            <Input type="text"  value={ role } onChange={ setRole } />
          </div>
          <div>
            <div className="text-sm font-medium">Título da Assinatura</div>
            <Input type="text"  value={ signRole } onChange={ setSignRole } />
          </div>
          <div>
            <div className="text-sm font-medium">Assinatura</div>
            <Input type="text"  value={ sign } onChange={ setSign } />
          </div>
        </div>
        <Button onClick={ exportCard }>Gerar Imagem</Button>
      </div>
      <div className="flex gap-3">
        <div>
          <div className="text-sm font-medium">Prévia:<span className="font-normal italic"> (desconsidere o deslocamento dos textos)</span></div>
          <div ref={ cardRef } className="w-[422px] flex flex-col overflow-hidden rounded-lg font-[Montserrat] relative">
            <div className="px-3 py-2 text-white w-full bg-slate-700 flex items-center gap-2 text-sm font-bold">
              <div className="w-[24px]">
                <LogoSVG />
              </div>
              <div className="ml-auto -mt-4">TRIBUNAL REGIONAL DO CIDADE ALTA</div>
            </div>
            <div className="bg-gray-300 h-full w-full pt-2 p-3 flex flex-col relative overflow-hidden">
              <img src="map.png" className="absolute bottom-0 left-0 opacity-70" alt="" />
              <div className="flex gap-3 mt-1 z-10">
                <div className="h-[170px] w-[110px] bg-white rounded-xl flex-shrink-0 z-20" style={ { background: `url(${pictureUrl}) center/cover` } }></div>
                <div className="w-full text-sm font-bold text-slate-800">
                  <div className="text-xs opacity-50">Nome Completo</div>
                  <div>{ name }</div>
                  <div className="text-xs mt-1 opacity-50">Passaporte</div>
                  <div>{ passport }</div>
                  <div className="text-xs mt-1 opacity-50">Cargo</div>
                  <div className="text-yellow-600">{ role }</div>
                  <div className="text-xs mt-1 opacity-50">{ signRole }</div>
                  <div className="font-['Passions_Conflict'] font-normal text-black -mt-3 text-[40px]">{ sign }</div>
                </div>
              </div>
              <div>
                <img src="law.png" className="w-[70px] opacity-20 absolute right-3 top-3" alt="" />
                <div className="w-[70px] opacity-40 text-yellow-600 absolute bottom-3 right-3">
                  <LogoSVG />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[422px]">
          <div className="text-sm font-medium">Resultado:</div>
          <img src={ imageUrl } alt="" />
        </div>
      </div>
    </div>
  )
};