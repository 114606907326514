import { FC, ReactNode, useCallback } from "react";

export const Button: FC<{ children?: ReactNode, onClick?: Function}> = props =>
{
  const { children, onClick } = props;

  const handleOnClick = useCallback(() =>
  {
    if (onClick) onClick();
  }, [ onClick ]);

  return (
    <button
      type="button"
      onClick={ handleOnClick }
      className={ `flex items-center gap-2 w-full transition-colors justify-center text-sm rounded-md bg-sky-500 py-2 px-3 font-semibold text-white hover:bg-sky-600 shadow-sm whitespace-nowrap` }
    >
      { children }
    </button>
  );
};